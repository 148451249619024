<template>
	<div class="columns">
		<b-notification
			:closable="false"
			class="notification"
		>
			<b-loading
				v-model="loading"
				:is-full-page="true"
				:can-cancel="false"
			/>
		</b-notification>
		<transition name="fade">
			<div
				v-if="!loading"
				class="column is-10-tablet is-offset-1-tablet is-10-fullhd"
			>
				<Title>Pagamentos</Title>

				<div class="panel">
					<Pagamento :fatura="fatura" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Title from '@components/Title'
import Pagamento from '@components/meus_pagamentos/Pagamento'

export default {
	name: "PagamentoCartao",
	components: {
		Title,
		Pagamento,
	},
	data() {
		return {
			loading: false,
			nome: "",
			matricula: "",
			fatura: {}
		}
	},
	async created() {
		this.loading = true;
		this.fatura = this.$store.state.fatura;
		this.nome = this.$store.state.aluno.dadosPessoais.nome;
		this.matricula = this.$store.state.alunoMatricula;
		this.loading = false;
	},
}
</script>
